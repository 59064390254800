<template>
  <div class="container">
    <PageTitle marginBottom>Create Video Lessons</PageTitle>
    <LessonForm @submit="submit" submitText="Create" />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import LessonForm from "@/views/toefl/lessons/LessonForm.vue";
import lessonsApi from "@/apis/lessons";

export default {
  metaInfo() {
    return {
      title: "Create Video Lessons - " + this.CompanyName
    };
  },
  components: {
    PageTitle,
    LessonForm
  },
  methods: {
    async submit(lesson) {
      try {
        await lessonsApi.createLesson(lesson);
        this.$message.success(this.$t("message.create_success"));
        this.$router.push({ name: "Lessons" });
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 40px;
}
</style>
